// API
import { apiUrl, apiAuthToken } from '@/settings.js'
import axios from 'axios'

export default class Api {
	constructor (args = {}) {
			// Constructor Variables
			this.headers = args.headers || null
			this.callback = args.callback
			this.data = args.data || null
			this.logLabel = args.logLabel || null
			this.query = args.query || null
			this.status = 'idle'
			this.type = args.type || 'gql'
			this.url = args.url || apiUrl
			this.variables = args.variables || null
			this.preview = args.preview || null

			if (this.url) {
			  this.request(this.variables)
	    	}
	}

    gqlRequest (query, variables = null) {
		var vue = this
		// Load Page Content Goes Here

        var paramsVariables = {}
        if (this.variables) {
        	// If Preview Parameters are provided use them to render the page
        	if (this.variables.preview.token) {
	        	paramsVariables.token = this.variables.preview.token
	        }
        }

		axios({
			url: this.url,
			method: 'post',
			data: {
		  		query: this.query || query,
		  		variables: this.variables || variables
			},
			headers: {
				Authorization: `Bearer ${apiAuthToken}`,
				'Content-Type': 'application/json;charset=UTF-8'
			},
			params: paramsVariables
		})
		.then(function (response) {
			if (typeof vue.callback === 'function' && response) {
				if (response.data) {
					// Success
					vue.callback(response.data)
				} else {
					// Failed
					vue.callback(null)
					console.log('Failed to Return Data')
				}
	        }
		})
		.catch(function (error) {
			console.log('got an error')
			console.log(error)
		})
    }

    request (variables = {}) {
    	switch (this.type) {
    		case 'gql':
    		this.gqlRequest(this.query, variables || null)
    	}
    }
}
