// SEOMATIC
export default class Seomatic {
	// This function takes in Raw SEOMatic Data and returns it in a form that works with Vue Meta
	// Future Enhancements add JSON-LP https://vue-meta.nuxtjs.org/api/#script
	constructor (args = {}) {
		// Add Later
		this.title = args.title || null
		this.metaTags = args.metaTags || null
		this.metaData = args.metaData || null
	}

	setMetaData (seomaticData = {}) {
		// This function gets meta data from the SEOmatic API.
		// ----------------------------------------------------
		// Set Meta Title
		// ----------------------------------------------------
		var metaTitleContainer = JSON.parse(seomaticData.metaTitleContainer)
		if (metaTitleContainer.title.title) {
			this.title = metaTitleContainer.title.title
		}
		// ----------------------------------------------------
		// Set Meta Tags
		// ----------------------------------------------------
		this.metaTags = []
		var metaTagContainer = JSON.parse(seomaticData.metaTagContainer)
		// console.log('Meta Tag Container')
		// console.log(metaTagContainer)
		// Add Description
		if (metaTagContainer.description) {
			this.metaTags.push(metaTagContainer.description)
		}
		// Add Keywords
		if (metaTagContainer.keywords) {
			this.metaTags.push(metaTagContainer.keywords)
		}
		// Add Robots
		if (metaTagContainer.robots) {
			this.metaTags.push(metaTagContainer.description)
		}
		// ----------------------------------------------------
		// Create & Return Meta Data Object
		// ----------------------------------------------------
		this.metaData = {
			title: this.title,
			tags: this.metaTags
		}
		return this.metaData
	}
}
