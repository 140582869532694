import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Routes
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Google Analytics
Vue.use(VueGtag, {
  config: {
  	id: 'G-ZEHXG7YVV9'
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
