<template>
  <div id="app">
    <global-header></global-header>
    <router-view/>
    <global-footer></global-footer>
  </div>
</template>
<script>
  import GlobalHeader from '@/components/GlobalHeader.vue'
  import GlobalFooter from '@/components/GlobalFooter.vue'
  export default {
    name: 'App',
    metaInfo () {
        return {
            title: 'Larry Herb III Portfolio'
        }
    },
    components: {
      GlobalHeader,
      GlobalFooter
    },
    mounted () {}
  }
</script>
<style lang="scss">
//Bootstrap overide
@import "./assets/fonts/nexa/MyFontsWebfontsKit.css";
@import './assets/scss/custom.scss';
@import './assets/scss/mixins.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap-vue/src/index.scss';
</style>
