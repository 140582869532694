<template>
  <div class="loader" v-bind:class="[activeClass, type]">
    <div class="loader-container">
      <div class="loading-indicator">
        <svg class="loader-graphic" v-bind:class="[loader.sizeClass]">
          <circle class="circle" v-bind:cx="loader.size" v-bind:cy="loader.size" v-bind:r="loader.size-2"></circle>
        </svg>
        <div class="loader-label">
          <label class="Nexa-Bold">LOADING...</label>
        </div>
      </div>
      <div class="background"></div>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        loader: {
          size: 40,
          sizeClass: 'large'
        },
        activeClass: String
      }
    },
    name: 'Loader',
      props: {
      type: String
    },
    methods: {
      init () {
        // Initialization Function, Determine Which Class to load
        if (this.type === 'page') {
          this.loader.size = 40
          this.loader.sizeClass = 'large'
        } else {
          this.loader.size = 18
          this.loader.sizeClass = 'small'
        }
      },
      startAnimation: function () {
        this.activeClass = 'active'
      },
      stopAnimation: function () {
        this.activeClass = null
      }
    },
    mounted () {
      // Initial Function
      this.init()
    }
  }
</script>
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  @import "src/assets/scss/mixins.scss";
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  .loader{
    @include transition(all 0.5s ease);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0;
    .loader-container{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .loading-indicator{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        width: 100px;
        .loader-graphic{
          width: 80px;
          height: 80px;
          animation:rotate 1s linear infinite;
          .circle{
            fill:none;
            stroke-width:4px;
            stroke-linecap: round;
            stroke:$primary-color;
            stroke-dasharray: 220;
            stroke-dashoffset:220;
            animation:animate 4s ease-in infinite;
          }
          @keyframes animate{
            0%, 100%{
              stroke-dashoffset:220;
            }
            50%{
              stroke-dashoffset:0;
            }
            50.1%{
              stroke-dashoffset:440;
            }
          }
          /* Graphic Here */
        }
        .loader-graphic.large{
          width: 80px;
          height: 80px;
        }
        .loader-graphic.small{
          width: 36px;
          height: 36px;
        }
        @keyframes rotate{
          0%{
            transform:rotate(0deg);
          }
          100%{
            transform:rotate(360deg);
          }
        }
        .loader-label{
          margin-top: 20px;
          text-align: center;
          label{
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 1px;
          }
        }
      }
      .background{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: -99;
        background-color: white;
      }
    }
  }
  .loader.active{
    opacity: 1;
    display: flex;
  }
  .loader.page{
    position: relative;
    height: 100vh;
  }
  .loader.component{
    position: absolute;
    width: 100%;
    height: 100%;
    .loader-container{
      .loader-label{
        display: none;
      }
    }
  }
</style>
