<template>
  <div class="project-card">
    <router-link class="thumbnail-link" v-bind:to='/project/+slug' v-bind:aria-label="this.title">
      <div class="thumbnail-container">
        <div class="indicator-container">
          <div class="indicator">
            <div class="icon"></div>
          </div>
        </div>
        <div class="overlay"></div>
        <div class="image-container">
          <div class="image" v-bind:style="'background-image:url('+image.widescreenImage.srcWebp+');'"></div>
          <div class="placeholder-image" v-bind:style="'background-image:url('+image.widescreenImage.placeholderImage+');'"></div>
        </div>
      </div>
    </router-link>
    <span class="title NexaText-Bold">
      <router-link v-bind:to='/project/+slug' v-bind:aria-label="this.title">{{title}}</router-link>
    </span>
    <span class="description NexaText-Light">{{description}}</span>
  </div>
</template>
<script>
  export default {
    name: 'ProjectCard',
      props: {
      title: String,
      description: String,
      image: {},
      slug: String
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  @import "src/assets/scss/mixins.scss";
  .project-card{
    margin-bottom: 20px;
    position: relative;
    .thumbnail-link{
      position: relative;
      .thumbnail-container{
        @include aspect_ratio(16, 9);
        background-color: red;
        overflow:hidden;
        .indicator-container{
          @include transition(all 0.25s ease-out);
          position: absolute;
          top: 100%; left: 0px;
          width: 100%; height: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          z-index: 4;
          .indicator{
            @include transition(all 0.05s ease-in);
            opacity: 0.0;
            border:#ffffff solid 1px;
            border-radius: 50px;
            width: 50px;
            height: 50px;
            display: flex;
            .icon{
              border-radius: 50px;
              width: 50px;
              height: 50px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 50% 50%;
              background-image: url('/img/icons/expand_plus_icon.svg');
            }
          }

        }
        .overlay{
          @include transition(all 0.25s ease);
          position: absolute;
          left: 0px;
          top: 0px;
          display: flex;
          background-color: $primary-color-dark;
          width: 100%;
          height: 100%;
          z-index: 3;
          color:#ffffff;
          opacity: 0.0;
        }
        .image-container{
          @include transition(all 0.15s ease-out);
          position: absolute;
          top: 0px;
          left: 0px;
          background-color: red;
          background-size: cover;
          width: auto;
          min-width: 100%;
          height: 100%;
          min-height: 100%;
          z-index: 2;
          .image, .placeholder-image{
            position: absolute;
            top: 0px; left: 0px;
            width: 100%;
            height: 100%;
            background-size: cover;
          }
          .image{
            z-index: 2;
          }
          .placeholder-image{
            z-index: 1;
          }
        }
      }
    }
    .thumbnail-link:hover{
      .thumbnail-container{
        .overlay{
          opacity: 0.9;
        }
      }
      .image-container{
        transform: scale(1.05, 1.05);
      }
      .indicator-container{
        top: 0px;
        .indicator{
          opacity: 1.0;
        }
      }
    }
    .title, .description{
      display: block;
    }
    .title{
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 10px;
      a{
        color:#000;
      }
    }
    .description{
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
</style>
