<template>
  <div class="home">
    <loader ref="loader" type="page"></loader>
    <div id="page-content" v-bind:class="[pageContentVisibilityState]">
      <section id="hero-project" v-if="heroProject[0]">
        <div class="hero-visual-container">
            <img class="hero-img" v-bind:data-src="heroProjectImage[0].uncroppedImage.srcWebp" v-bind:src="heroProjectImage[0].uncroppedImage.placeholderImage" v-bind:srcset="heroProjectImage[0].uncroppedImage.srcsetWebp" v-bind:alt="heroProject[0].title+' image'" v-bind:style="'background-image: url('+heroProjectImage[0].uncroppedImage.placeholderImage+')'" />
        </div>
        <div class="hero-about-container">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-8 d-flex align-items-center ">
                <div class="w-100">
                  <div class="project-headline-container">
                    <label class="NexaText-Regular">Featured Project</label>
                  </div>
                  <div class="project-name-container">
                    <label class="Nexa-Bold">{{heroProject[0].title}}</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
                  <router-link v-bind:to='/project/+heroProject[0].slug' class="primary-cta-hero btn btn-primary NexaText-Bold" aria-label="View Project">View Project &#187;</router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="intro-bio" v-if="homepageIntro">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col text-center col-sm-12 col-md-6 col-lg-8" >
              <h1 class="Nexa-Bold">Welcome</h1>
              <div class="content-container NexaText-Light" v-html="homepageIntro"></div>
              <router-link to="/about" class="btn cta-btn NexaText-Bold" aria-label="About Me">About Me &#187;</router-link>
            </div>
          </div>
        </div>
      </section>
      <section id="featured-projects" v-if="featuredProjects[0]">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h1 class="Nexa-Bold">Featured Projects</h1>
            </div>
          </div>
          <div class="row">
            <!--[START] Project Item-->
            <div class="col-sm-12 col-md-4 col-lg-4" v-for="(project, key) in featuredProjects" v-bind:key="key">
              <project-card
                v-bind:title="project.title"
                v-bind:description="project.projectDescription"
                v-bind:slug="project.slug"
                v-bind:image="project.projectImage[0]"
              ></project-card>
            </div>
            <!--[END] Project Item-->
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Loader from '@/components/Loader.vue'
import { gql } from '@/gql/views/home.js'
import Api from '@/api.js'
import Seomatic from '@/seomatic.js'
import ProjectCard from '@/components/ProjectCard.vue'

export default {
  name: 'Home',
  metaInfo () {
      return {
          title: this.metaData.title,
          meta: this.metaData.tags
      }
  },
  data () {
    return {
      homepageIntro: '',
      heroProject: [],
      heroProjectImage: null,
      featuredProjects: [],
      pageContentVisibilityState: '',
      metaData: {}
    }
  },
  components: {
    ProjectCard,
    Loader
  },
  props: {
    msg: null
  },
  methods: {
    loadPage: function () {
      // Load the Page Function and Get Data
      const api = new Api({
        callback: (response) => {
          // Response Gotten
          if (response.data.entry) {
            var entry = response.data.entry
            this.heroProject = entry.heroProject
            this.homepageIntro = entry.homepageIntro
            this.heroProjectImage = entry.heroProjectImage
            this.featuredProjects = entry.homepageFeaturedProjects
            const seomatic = new Seomatic()
            this.metaData = seomatic.setMetaData(entry.seomatic)
          }
          this.$router.onReady(() => {
            this.$refs.loader.stopAnimation()
            this.pageContentVisibilityState = 'active'
          })
        },
        query: gql,
        variables: {
          preview: {
            token: this.$route.query.token
          }
        }
      })
      console.log(api)
    }
  },
  mounted () {
    this.$router.onReady(() => {
      this.$refs.loader.startAnimation()
      this.loadPage()
    })
  }
}
</script>
<style lang="scss">
  @import "src/assets/scss/custom.scss";
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  #page-content{
    display: none;
    opacity: 0.0;
  }
  #page-content.active{
    display: block;
    opacity: 1.0;
    animation-delay: 1s;
    animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  #hero-project{
    .hero-visual-container{
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      padding-left: 20%;
      padding-right: 20%;
      height: 50vh;
      display: flex;
      position: relative;
      align-items: center;
      align-content: center;
      justify-content: center;
      .hero-img{
        width: auto;
        height: auto;
        max-width: 540px; /* Max Small Width*/
        max-height: 100%;
        background-size: contain;
        background-position: center;
      }
    }
    .hero-about-container{
      background-color: #f0f0f0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      .project-headline-container{
        label{
          font-size: 15px;
          font-weight: 400;
          color: #666;
        }
      }
      .project-name-container{
        label{
          font-weight: 500;
          font-size: 18px;
          color: #000;
        }
      }
      .btn{
        padding:15px;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
        border:none;
        text-transform: uppercase;
        letter-spacing: 1.0px;
        background-color: $primary-color-dark;
      }
      .btn:hover{
        background-color: black;
      }
    }
  }
  #intro-bio{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #222;
    color:#fff;
    h1{
      font-size:22px;
      font-weight: 500;
      display: block;
      text-transform: uppercase;
      letter-spacing: 2.0px;
    }
    .content-container{
      display: block;
      margin-bottom: 30px;
    }
    p{
      font-size: 18px;
      font-weight: 300;
    }
    .cta-btn{
      margin-top: 30px;
      background-color: none;
      border: #fff 3px solid;
      padding: 10px;
      display: block;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1.0px;
    }
  }
  #featured-projects{
    padding-top: 30px;
    padding-bottom: 30px;
    h1{
      font-size: 22px;
      font-weight: 500;
      padding-bottom: 30px;
      text-transform: uppercase;
      letter-spacing: 2.0px;
    }
  }
  @include media-breakpoint-up(xs) {
  }
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {
    #hero-project{
      .hero-visual-container{
        padding-top: 30px;
        padding-bottom: 30px;
        height: 65vh;
        .hero-img{
          max-width: 720px;
        }
      }
      .hero-about-container{
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: left;
        .project-headline-container{
          label{
            font-size: 15px;
          }
          /*background-color: red;*/
        }
        .project-name-container{
          label{
            font-size: 18px;
          }
        }
        .btn{
          width: auto;
        }
      }
    }
    #intro-bio{
      padding-top: 45px;
      padding-bottom: 45px;
      h1{
        font-size: 24px;
      }
      p{
        font-size: 20px;
      }
      .cta-btn{
        margin-top: auto;
        display: inline-block;
        padding-left: 30px;
        padding-right: 30px;
      }
      .cta-btn:hover{
        background-color: #fff;
        color: #000;
      }
    }
  }
  @include media-breakpoint-down(md) {
  }
  @include media-breakpoint-up(lg) {
    #hero-project{
      .hero-visual-container{
        height: 70vh;
        .hero-img{
          max-width: 960px;
        }
      }
    }
    #intro-bio{
      padding-top: 75px;
      padding-bottom: 75px;
      h1{
        font-size: 30px;
      }
      p{
        padding-top: 20px;
        font-size: 20px;
      }
      .cta-btn{
        margin-top: auto;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    #featured-projects{
      padding-top: 75px;
      padding-bottom: 75px;
      h1{
        font-size: 30px;
        margin-bottom: 35px;
        font-weight: 500;
      }
      .cta-btn{
        margin-top: auto;
        padding-left: 60px;
        padding-right: 100px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    #hero-project{
      .hero-visual-container{
        height: 70vh;
        .hero-img{
          max-width: 1140px;
        }
      }
    }
  }
</style>
