<template>
  <div class="global-footer">
    <div class="container">
      <div class="row footer-container"><!--row-cols-2-->
        <div class="col-small-12 col-md-12 col-lg-3">
          <label class="d-flex align-items-center title-container">
            <router-link to="/" class="Nexa-Bold" aria-label="Home">LARRY HERB III</router-link>
          </label>
        </div>
        <div class="col-small-12 col-md-12 col-lg-9 footer-navigation Nexa-Regular">
          <ul>
            <li>
              <router-link to="/" class="nav-link" aria-label="Home">Home</router-link>
            </li>
            <li>
              <router-link to="/about" class="nav-link" aria-label="About">About</router-link>
            </li>
            <li>
              <router-link to="/projects" class="nav-link" aria-label="Projects">Projects</router-link>
            </li>
            <li>
              <router-link to="/contact" class="nav-link" aria-label="Contact">Contact</router-link>
            </li>
            <li>
              <a href="https://www.github.com/lherb3" target="black" alt="Github" class="nav-link" aria-label="Github">Github</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col text-center NexaText-Light">
          <label class="copyright-notice">{{copyrightNotice}}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { gql } from '@/gql/components/footer.js'
  import Api from '@/api.js'
  export default {
    name: 'GlobalFooter',
      props: {
      msg: String
    },
    data () {
      return {
        copyrightNotice: ''
      }
    },
    methods: {
    loadComponent: function () {
        // Load the Page Function and Get Data
        const api = new Api({
          callback: (response) => {
            // Response Gotten
            if (response.data.globalSet) {
              var footerData = response.data.globalSet
              this.copyrightNotice = footerData.footerCopyrightNotice
            }
          },
          query: gql,
          variables: null
        })
        console.log(api)
      }
    },
    mounted () {
      this.loadComponent()
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  .global-footer{
    height:auto;
    padding-top:25px;
    padding-bottom: 25px;
    background-color: black;
    color:white;
    .title-container{
      text-align: center;
      width: 100%;
      a{
        @include transition(all 0.5s ease);
        font-size:15px;
        font-weight:500;
        padding: 10px;
        width: 100%;
        letter-spacing:2px;
        color:#ccc;
        border-bottom:none;
        text-decoration: none;
      }
      a:hover{
        color:#fff;
      }
    }
    .footer-navigation{
      ul{
        padding: 15px;
        margin: 0px;
        li{
          @include transition(all 0.5s ease);
          list-style: none;
          .nav-link{
            @include transition(all 0.5s ease);
            display: block;
            width: 100%;
            padding: 10px;
            border-bottom: #222 solid 1px;
            background-color: transparent;
            text-align: center;
            font-size: 15px;
            color: #ccc;
            text-decoration: none;
          }
        }
        li:hover{
          background-color: #111;
          .nav-link{
            color:#ffffff;
          }
        }
      }
    }
    .copyright-notice{
      font-size: 14px;
      color: #ccc;
      padding: 5px;
    }
  }
  @include media-breakpoint-up(xs) {
    /**/
  }
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-down(md) {
  }
  @include media-breakpoint-up(lg) {
    .global-footer{
      .footer-container{
        border-bottom: #222 solid 1px;
        margin-bottom: 15px;
      }
      .title-container{
        text-align: left;
        width: auto;
        a{
          /**/
        }
      }
      .footer-navigation{
        text-align: right;
        ul{
          padding: 0px;
          margin: 0px;
          li{
            display: inline-block;
            .nav-link{
              display: inline-block;
              width: auto;
              padding: auto;
              border-bottom: none;
            }
          }
        }
      }
    }
  }
</style>
