export const gql = `
query{
  entry(
    section:"home"
  ){
    id
    title
    ... on home_home_Entry{
      heroProjectImage{
        url
        ... on home_Asset{
          uncroppedImage{
            srcset
            src
            srcWebp
            srcsetWebp
            placeholderSilhouette
            placeholderImage
            placeholderWidth
            placeholderHeight
            placeholderBox
            placeholderSvg
          }
          widescreenImage{
            srcset
            src
            srcWebp
            srcsetWebp
            placeholderSilhouette
            placeholderImage
            placeholderWidth
            placeholderHeight
            placeholderBox
            placeholderSvg
          }
        }
      }
      heroProject {
        id
        title
        slug
      }
      homepageIntro
      homepageFeaturedProjects{
        id
        title
        slug
        ... on projects_projects_Entry{
          projectDescription
          projectImage{
            url
            ... on projects_Asset{
              widescreenImage{
                srcset
                src
                srcWebp
                srcsetWebp
                placeholderSilhouette
                placeholderImage
                placeholderWidth
                placeholderHeight
                placeholderBox
                placeholderSvg
              }
            }
          }
        }
      }
    }
    seomatic(asArray: true){
      metaTitleContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaTagContainer
    }
  }
}`
