<template>
  <div class="global-header-container">
    <div class="global-header">
      <div class="container first-container">
        <div class="row row-cols-2">
          <div class="col">
            <div class="d-flex align-items-center title-container">
              <router-link to="/" class="Nexa-Bold" aria-label="Home">LARRY HERB III</router-link>
            </div>
          </div>
          <div class="col text-right">
            <button id="mobile-menu-btn" class="hamburger hamburger--spin" v-bind:class="mobileMenuBtnStateClass" type="button" aria-label="Menu" v-on:click="toggleMainMenu()" >
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
            </button>
            <div class="align-items-center justify-content-end navigation-container Nexa-Regular">
              <router-link to="/" aria-label="Home">Home</router-link>
              <router-link to="/about" aria-label="About">About</router-link>
              <router-link to="/projects" aria-label="Projects">Projects</router-link>
              <router-link to="/contact" aria-label="Contact">Contact</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="mobile-menu" class="mobile-navigation" v-bind:class="mobileMenuStateClass">
      <ul>
        <li>
          <button v-on:click="loadMobileLink('/')" class="nav-link Nexa-Regular" aria-label="Home">Home</button>
        </li>
        <li>
          <button v-on:click="loadMobileLink('/about')" class="nav-link Nexa-Regular" aria-label="About">About</button>
        </li>
        <li>
          <button v-on:click="loadMobileLink('/projects')" class="nav-link Nexa-Regular" aria-label="Projects">Projects</button>
        </li>
        <li>
          <button v-on:click="loadMobileLink('/contact')"  class="nav-link Nexa-Regular" aria-label="Contact">Contact</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'GlobalHeader',
      props: {
      msg: String
    },
    data () {
      return {
        mobileMenuBtnStateClass: '',
        mobileMenuStateClass: ''
      }
    },
    methods: {
      toggleMainMenu: function () {
        // Toggle on and Off Main Menu Function

        if (this.mobileMenuBtnStateClass === 'is-active') {
          this.mobileMenuBtnStateClass = ''
          this.mobileMenuStateClass = ''
          // $('html, body').css('overflowY', 'scroll')
        } else {
          this.mobileMenuBtnStateClass = 'is-active'
          this.mobileMenuStateClass = 'active'
          // $('html, body').css('overflowY', 'hidden')
        }
      },
      loadMobileLink: function (routerUrl) {
        this.$router.push(
          {
            path: routerUrl,
            params: null
          }
        )
        this.toggleMainMenu()
      }
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  // Hamburger Menu
  $hamburger-padding-x : 15px !default;
  $hamburger-padding-y                       : 12px !default;
  $hamburger-layer-width                     : 20px !default;
  $hamburger-layer-height                    : 2px !default;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2/1) {
    $hamburger-layer-height : 2.5px !default;
  }
  $hamburger-layer-spacing                   : 5px !default;
  $hamburger-layer-color                     : #fff !default; // black
  $hamburger-layer-border-radius             : 4px !default;
  $hamburger-hover-opacity                   : 1 !default;
  $hamburger-hover-transition-duration       : 0.15s !default;
  $hamburger-hover-transition-timing-function: linear !default;
  /*modify global_header stylesheet as well to reuse.*/
  @import "src/assets/scss/custom.scss";
  @import "~hamburgers/_sass/hamburgers/hamburgers.scss";
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  #mobile-menu-btn{
    font-size: 100%;
    font-family: inherit;
    border:0;
    padding: 0;
    width: 50px;
    height: 50px;
    margin-right:-15px;
  }
  #mobile-menu-btn:hover{
    background-color: #222;
    cursor: pointer;
  }
  #mobile-menu-btn.is-active{
    background-color: #000;
    .hamburger-box{
      .hamburger-inner, .hamburger-inner::after{
        background-color: #fff;
      }
    }
  }
  #mobile-menu-btn.is-active:hover{
    background-color: #222;
  }
  .global-header-container{
    position: relative;
    padding-bottom: 50px;
  }
  .global-header{
    position: fixed;
    width:100%;
    top:0px;
    background-color:black;
    color:#fff;
    height: 50px;
    z-index: 10000;
    .title-container{
      height: 50px;
      a{
        @include transition(all 0.5s ease);
        font-size:15px;
        font-weight:500;
        letter-spacing:2px;
        color:#fff;
        border-bottom:none;
        text-decoration: none;
      }
      a:hover{
        color:#00A5FD;
      }
    }
    .navigation-container{
      height:50px;
      text-align: right;
      display: flex;
      a{
        @include transition(all 0.5s ease);
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        padding: 10px;
        color: #fff;
        text-decoration: none;
        border-bottom:#000 0px solid;
        cursor: pointer;
      }
      a:hover{
        border-bottom:$primary-color 5px solid;
        cursor: pointer;
      }
      a:active{
        border-bottom:$primary-color 5px solid;
      }
    }
  }
  .mobile-navigation{
    position: fixed;
    top: 50px;
    bottom: 50px;
    left: 0px;
    right: 0px;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
    display: none;
    ul{
      padding: 15px;
      margin: 0px;
      li{
        @include transition(all 0.5s ease);
        list-style: none;
        .nav-link{
          @include transition(all 0.5s ease);
          display: block;
          background:none;
          border:none;
          width: 100%;
          padding: 25px;
          border-bottom: #f0f0f0 solid 1px;
          background-color: transparent;
          text-align: center;
          font-size: 18px;
          color: #666;
        }
      }
      li:hover{
        background-color: #f0f0f0;
        .nav-link{
          color:#000;
        }
      }
    }
  }
  .mobile-navigation.active{
    display: block;
  }
  @include media-breakpoint-up(xs) {
    .global-header{
      background-color: black;
    }
  }
  @include media-breakpoint-up(sm) {
    .global-header{
      background-color: black;
    }
  }
  @include media-breakpoint-up(md) {
    .global-header{
      background-color: black;
    }
  }
  @include media-breakpoint-down(md) {
    .global-header{
      .first-container{
        /*padding-right:0px;*/
      }
      .navigation-container{
        display: none;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    #mobile-menu-btn{
      display: none;
    }
    .global-header{
      position: relative;
      height: 60px;
      .title-container{
        height: 60px;
          a{
          font-size:18px;
          font-weight:500;
          letter-spacing:3px;
        }
      }
      .navigation-container{
        height:60px;
        a{
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }
    }
    .global-header-container{
      padding-bottom: 0px;
    }
  }
</style>
